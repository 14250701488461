import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/investor',
    name: 'investor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InvestorView.vue')
  },
  {
    path: '/job',
    name: 'job',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/JobView.vue')
  },
  {
    path: '/news/:id:type',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue')
  },
  {
    path: '/member',
    name: 'member',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MemberView.vue')
  },
  {
    path: '/patient',
    name: 'patient',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PatientView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // 获取当前路由的 meta 信息
  // const { meta } = to.meta;
  // 显式声明 meta 变量的类型
  const meta: { title?: string, description?: string } = to.meta;
  // 设置页面标题
  document.title = meta.title || '思捷優達(Yoda)';

  // 清空旧的 meta 标签
  const head = document.head;
  const existingMetaTags = head.querySelectorAll('meta[name="description"]');
  existingMetaTags.forEach(tag => head.removeChild(tag));

  // 动态添加 meta 标签
  const descriptionMeta = document.createElement('meta');
  descriptionMeta.name = 'description';
  descriptionMeta.content = meta.description || '英屬開曼群島商思捷優達股份有限公司由台灣大學、陽明交大、國家衛生研究院等研究學者與精神科醫師於2019 年攜手創立， 孵化自台灣國家型計畫與美國史丹佛大學的全球SPARK計畫，是一家以AI 人工智慧技術為基石，專注於中樞神經系統疾病（CNS）領域的新藥開發公司。 \n 開發中產品YA-101，業於2022年獲得美國食品藥品監督管理局（FDA）授予的多重系統退化症 (Multiple System Atrophy, MSA) 孤兒藥認證， 並已進入一期臨床試驗。思捷優達目前在神經與精神疾病領域有多個新藥研發產品線，適應症包括思覺失調症、阿茲海默症、多重系統退化症等，秉持著致力於填補醫療需求空白的使命，致力於 為急需創新、有效、安全和便捷藥物的精神與神經退化性疾​​病患者帶來新的治療選擇。';
  head.appendChild(descriptionMeta);

  // 继续路由导航
  next();
});

export default router
