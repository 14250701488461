import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "title",
  class: "flex flex-col justify-center bg-primary-500"
}
const _hoisted_2 = { class: "bg-blackmask-600 sm:mt-10 md:-mt-32 h-fit sm:ml-0 md:ml-40 sm:w-full md:w-3/5 flex flex-col flex-wrap text-white p-5 rounded border-none transition duration-300 delay-150" }
const _hoisted_3 = { class: "sm:text-base md:text-3xl font-extrabold" }
const _hoisted_4 = { class: "mt-10 font-thin sm:text-xs md:text-lg" }
const _hoisted_5 = { class: "mt-10 font-thin sm:text-xs md:text-lg" }
const _hoisted_6 = { class: "sm:px-2 md:p-28 pt-10 flex felx-col w-full flex-wrap bg-gray-50" }
const _hoisted_7 = { class: "w-full sm:text-base md:text-xl font-semibold text-second-500 text-center mb-5" }
const _hoisted_8 = { class: "w-full flex flex-row rounded shadow" }
const _hoisted_9 = {
  key: 0,
  class: "pt-5 justify-center grid md:grid-cols-4 sm:grid-rows-1 gap-2"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "pt-5 justify-center grid md:grid-cols-4 sm:grid-rows-1 gap-2"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "w-full flex flex-row flex-wrap py-5 justify-center sm:space-y-2 md:space-y-0 md:space-x-1 sm:space-x-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_pagination = _resolveComponent("n-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('HomeView.SloganTitle')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('HomeView.SloganParagraphFirst')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('HomeView.SloganParagraphSecond')), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('HomeView.News')), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-1/2 bg-gray-50 text-center rounded py-2 sm:text-base md:text-xl font-extrabold hover:cursor-pointer hover:bg-blackmask-500", {'bg-second-500 text-gray-50': $setup.tab==1}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onTab(1)))
        }, _toDisplayString(_ctx.$t('HomeView.TabFirst')), 3),
        _createElementVNode("div", {
          class: _normalizeClass(["w-1/2 bg-gray-50 text-center rounded py-2 sm:text-base md:text-xl font-extrabold hover:cursor-pointer hover:bg-blackmask-500", {'bg-second-500 text-gray-50': $setup.tab==2}]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onTab(2)))
        }, _toDisplayString(_ctx.$t('HomeView.TabSecond')), 3)
      ]),
      (_ctx.$t('lange')=='zh')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            ($setup.tab==1 && $setup.newsData.length!=0 )
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.newsDataT.slice().reverse(), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["min-h-[40px] bg-gray-50 shadow rounded border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold cursor-pointer", {'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != $setup.page}]),
                    onClick: ($event: any) => ($setup.onNews(item,1))
                  }, [
                    _createElementVNode("p", null, _toDisplayString(item.date) + "｜" + _toDisplayString(item.title), 1)
                  ], 10, _hoisted_10))
                }), 256))
              : ($setup.tab==2 && $setup.knowleggeData.length!=0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.knowleggeDataT.slice().reverse(), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["min-h-[40px] bg-gray-50 shadow rounded border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold cursor-pointer", {'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != $setup.page}]),
                      onClick: ($event: any) => ($setup.onNews(item,2))
                    }, [
                      _createElementVNode("p", null, _toDisplayString(item.date) + "｜" + _toDisplayString(item.title), 1)
                    ], 10, _hoisted_11))
                  }), 256))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.$t('lange')=='en')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            ($setup.tab==1 && $setup.newsDataENG.length!=0 )
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.newsDataENGT.slice().reverse(), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["min-h-[40px] bg-gray-50 shadow rounded border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold cursor-pointer", {'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != $setup.page}]),
                    onClick: ($event: any) => ($setup.onNews(item,1))
                  }, [
                    _createElementVNode("p", null, _toDisplayString(item.date) + "｜" + _toDisplayString(item.title), 1)
                  ], 10, _hoisted_13))
                }), 256))
              : ($setup.tab==2 && $setup.knowleggeDataENGT.length!=0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.knowleggeDataENGT.slice().reverse(), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["min-h-[40px] bg-gray-50 shadow rounded border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold cursor-pointer", {'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != $setup.page}]),
                      onClick: ($event: any) => ($setup.onNews(item,2))
                    }, [
                      _createElementVNode("p", null, _toDisplayString(item.date) + "｜" + _toDisplayString(item.title), 1)
                    ], 10, _hoisted_14))
                  }), 256))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_n_pagination, {
          page: $setup.page,
          "onUpdate:page": _cache[2] || (_cache[2] = ($event: any) => (($setup.page) = $event)),
          "page-count": $setup.page_count,
          "on-update": "onPage"
        }, null, 8, ["page", "page-count"])
      ])
    ])
  ], 64))
}