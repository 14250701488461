<template>
    
    <Hearder  @scrollToSection="scrollToSection('#title')"></Hearder>
      <div id="content" class=" bg-slate-50 min-h-[100vh] flex flex-col items-center">
        <router-view ref="routerView"/>
      </div>
    <Footer class="min-h-[10vh]"></Footer>
  
</template>

<style lang="scss">

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Hearder from '@/layout/Header.vue'; // @ is an alias to /src
import Footer from '@/layout/Footer.vue'; // @ is an alias to /src
export default {
  components: {
    Hearder,
    Footer
  },
  setup() {
      
    const scrollToSection = (sectionId:string) =>  {
      console.log(`scrollToSection`);
      // const section = document.getElementById();
      const child = document.querySelector('#title')
      if(child){
        window.scrollTo({
          top:child.scrollHeight  ,
          behavior: "smooth"
        })
      }
    }
    return{
      scrollToSection
    }
  },
};
</script>
