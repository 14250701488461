import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "content",
  class: "bg-slate-50 min-h-[100vh] flex flex-col items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hearder = _resolveComponent("Hearder")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Hearder, {
      onScrollToSection: _cache[0] || (_cache[0] = ($event: any) => ($setup.scrollToSection('#title')))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, { ref: "routerView" }, null, 512)
    ]),
    _createVNode(_component_Footer, { class: "min-h-[10vh]" })
  ], 64))
}