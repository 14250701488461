<template>
    <div id="footer" class="bg-primary-500  flex flex-wrap md:flex-row sm:flex-col px-5 py-5 w-full  ">

      <div class="flex flex-col space-y-2 px-5 ">
        <router-link class="text-gray-100 cursor-pointer hover:text-first-500 "  to="/contact"> Contact us</router-link>
        <!-- <span class="text-gray-200 "> yota@mail.com</span> -->
        <span class="text-gray-200 "> Copyright 2024 Yoda Pharmaceuticals Inc. All Rights Reserved. </span>
      </div>

      <!-- <div class="flex flex-col space-y-2 px-5 ">
        <span class="text-gray-100 "> Contact us</span>
      </div> -->

      <!-- <div class="flex sm:flex-col md:flex-row text-gray-100 space-x-3 items-center " >
        <div class="flex flex-row"> 
            <span class="w-fit">姓名</span>
            <NInput v-model:value="data.name" size="small" placeholder="" class=" bg-second-600 shadow-none border-none"></NInput>
          </div>
        <div class="flex flex-col  space-y-3">
          <div class="flex flex-row"> 
            <span>信箱</span>
            <NInput  v-model:value="data.email" size="small" placeholder="" class=" bg-second-600 shadow-none border-none"></NInput>
          </div>
        </div>
        <div class="flex flex-row"> 
            <span>內文</span>
            <NInput  v-model:value="data.content" size="small" placeholder="" class=" bg-second-600 shadow-none border-none"></NInput>
          </div>
        <NButton class="bg-white text-second-500 "   @click="onSubmit"> 送出 </NButton>
      </div> -->
      

  </div>

</template>

<script lang="ts">
import { ref, onMounted} from "vue"
import router from "@/router"
import axios from "axios"
import { NButton, NInput } from "naive-ui"


export default {
    setup() {
        //const gapi = useGapi()
        let data = ref({
          name:"",
          email:"",
          content:""
        })
        const onSubmit = async() => {
          // console.log(data)
            await axios.post("/api/info", {
                "name": data.value.name,
                "email":data.value.email,
                "content": data.value.content,
            })
                .then((res: any) => {
                // console.log(res);
            });
        };
        return {
            onSubmit,
            data
        };
    },
    components: { NButton,NInput }
}

</script>