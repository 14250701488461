import { createI18n } from 'vue-i18n'
import zh from '@/resource/languages/zh-TW.json'
import en from '@/resource/languages/en-US.json'

type MessageSchema = typeof zh

const i18n = createI18n({
  legacy: false, // 要把 legacy 設為 false，才可以使用 Composition API
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  globalInjection: true,
  messages: {
    'zh-TW': zh,
    'en-US': en
  }
})

export const t = i18n.global.t

export default i18n