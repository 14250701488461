<template>
  <section id="title" class="flex flex-col justify-center bg-primary-500 ">
    <div class=" bg-blackmask-600 sm:mt-10 md:-mt-32 h-fit sm:ml-0 md:ml-40  sm:w-full md:w-3/5 flex flex-col flex-wrap text-white p-5 rounded border-none  transition duration-300 delay-150 ">
      <p class="sm:text-base md:text-3xl font-extrabold"> {{ $t('HomeView.SloganTitle') }}</p>
      <p class="mt-10 font-thin sm:text-xs md:text-lg"> {{ $t('HomeView.SloganParagraphFirst') }}</p>
        <p  class="mt-10 font-thin sm:text-xs md:text-lg"> {{ $t('HomeView.SloganParagraphSecond') }}</p>
        <!-- <div @click="onExpand(!ExpandFlag)" class="mt-10 flex flex-wrap w-fit rounded font-black items-center px-5 py-2 bg-slate-50 text-gray-800 hover:bg-blackmask-500 hover:cursor-pointer hover:text-white sm:text-xs md:text-base"> {{ $t('HomeView.ExporeMore') }}</div> -->
    </div>
    <!-- <img  class="sm:w-5 md:w-2/6" src="@/assets/image/yodaimage1.png">  -->
  </section>

  <section  class="sm:px-2 md:p-28 pt-10 flex felx-col w-full flex-wrap   bg-gray-50 ">
    <p class="w-full sm:text-base md:text-xl font-semibold text-second-500 text-center mb-5"> {{ $t('HomeView.News') }}</p>
      <div class="w-full flex flex-row rounded shadow "> 
        <div class="w-1/2 bg-gray-50   text-center rounded py-2 sm:text-base md:text-xl font-extrabold hover:cursor-pointer hover:bg-blackmask-500"
          :class="{'bg-second-500 text-gray-50': tab==1}"
          @click="onTab(1)"
        > {{ $t('HomeView.TabFirst') }}</div>
        <div class="w-1/2 bg-gray-50  text-center rounded py-2 sm:text-base md:text-xl font-extrabold hover:cursor-pointer hover:bg-blackmask-500"
        :class="{'bg-second-500 text-gray-50': tab==2}"  
        @click="onTab(2)"
        > {{ $t('HomeView.TabSecond') }}</div>
    </div>

    
    <div v-if="$t('lange')=='zh'"  class=" pt-5 justify-center grid md:grid-cols-4  sm:grid-rows-1 gap-2 ">
      <div v-if="tab==1 && newsData.length!=0 " v-for="item in newsDataT.slice().reverse()" class="min-h-[40px] bg-gray-50  shadow rounded   border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold  cursor-pointer"
        :class="{'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != page}"
        @click="onNews(item,1)"
      >

          <p>{{item.date}}｜{{ item.title }} </p>
      </div>
      <div v-else-if="tab==2 && knowleggeData.length!=0" v-for="item in knowleggeDataT.slice().reverse()" class="min-h-[40px] bg-gray-50  shadow rounded   border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold  cursor-pointer "
        :class="{'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != page}"
        @click="onNews(item,2)"
      >
          <p>{{item.date}}｜{{ item.title }} </p>
      </div>
      
    </div>

    <div v-if="$t('lange')=='en'"  class=" pt-5 justify-center grid md:grid-cols-4  sm:grid-rows-1 gap-2 ">
      <div v-if="tab==1 && newsDataENG.length!=0 " v-for="item in newsDataENGT.slice().reverse()" class="min-h-[40px] bg-gray-50  shadow rounded   border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold  cursor-pointer"
        :class="{'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != page}"
        @click="onNews(item,1)"
      >

          <p>{{item.date}}｜{{ item.title }} </p>
      </div>
      <div v-else-if="tab==2 && knowleggeDataENGT.length!=0" v-for="item in knowleggeDataENGT.slice().reverse()" class="min-h-[40px] bg-gray-50  shadow rounded   border-t-[10px] border-second-500 py-5 px-5 sm:text-base md:text-lg font-semibold  cursor-pointer "
        :class="{'bg-second-500 text-gray-50 border-t-[10px] border-second-500': item.id%2==0,
        'hidden': Math.ceil(item.id/4) != page}"
        @click="onNews(item,2)"
      >
          <p>{{item.date}}｜{{ item.title }} </p>
      </div>
      
    </div>

    <div  class="w-full flex flex-row flex-wrap py-5 justify-center sm:space-y-2 md:space-y-0 md:space-x-1 sm:space-x-0">
      <n-pagination v-model:page="page" :page-count="page_count" on-update="onPage"/>
    </div>
   

    
  </section>

  
</template>

<script lang="ts">
import {ref,reactive, onMounted} from "vue"
import newsData from "@/resource/CompanyNews.json"
import newsDataENG from "@/resource/english/CompanyNews_ENG.json"
import knowleggeData from "@/resource/KnowledgeNews.json"
import knowleggeDataENG from "@/resource/english/KnowledgeNews _ENG.json"
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  setup(){
    
    let ExpandFlag = ref(false)
    let tab = ref(1)
    const router = useRouter()
    const page = ref(2)
    const page_count = ref(10)
    const onExpand = (val:boolean) =>{
      ExpandFlag.value = val;
    }
    const data = ref()
    const bottomAnchor = ref();
  //  let newsDataT =   newsData.filter(item => item.id = (newsData.length - Number(item.id)+1).toString() )
    // let knowleggeDataT = knowleggeData.filter(item => item.id = (knowleggeData.length - Number(item.id)+1).toString() )
    let knowleggeDataT = ref<Array<{
      id: string;
      date: string;
      title: string;
      content: string;
      }>>([])
    let newsDataT = ref<Array<{
    id: string;
    date: string;
    title: string;
    content: string;
    }>>([])

    let knowleggeDataENGT = ref<Array<{
      id: string;
      date: string;
      title: string;
      content: string;
      }>>([])
    let newsDataENGT = ref<Array<{
    id: string;
    date: string;
    title: string;
    content: string;
    }>>([])

    knowleggeData.forEach((item)=>{
      let temp = (knowleggeData.length - Number(item.id)+1).toString() 
      knowleggeDataT.value.push({
        id: temp,
        date: item.date,
        title: item.title,
        content: item.content,
      })
    })
    // console.log(knowleggeData)
    // console.log(knowleggeDataT.value)
    newsData.forEach((item)=>{
      let temp = (newsData.length - Number(item.id)+1).toString() 
      newsDataT.value.push({
        id: temp,
        date: item.date,
        title: item.title,
        content: item.content,
      })
    })


    knowleggeDataENG.forEach((item)=>{
      let temp = (knowleggeDataENG.length - Number(item.id)+1).toString() 
      knowleggeDataENGT.value.push({
        id: temp,
        date: item.date,
        title: item.title,
        content: item.content,
      })
    })
    // console.log(knowleggeDataENGT.value)
    newsDataENG.forEach((item)=>{
      let temp = (newsDataENG.length - Number(item.id)+1).toString() 
      newsDataENGT.value.push({
        id: temp,
        date: item.date,
        title: item.title,
        content: item.content,
      })
    })
    // console.log(knowleggeDataT.value)
    const onTab = (val:number) =>{
      tab.value =val
      data.value = []
      page_count.value = 0
      page.value =1 
      if(tab.value ==1)
      {
        page_count.value = Math.ceil(newsData.length/4)
      }else if(tab.value==2){
        // console.log(Math.ceil(knowleggeData.length/4))
        page_count.value = Math.ceil(knowleggeData.length/4)
      }
    }
    const onNews = (val:any,type:number) =>{
      router.push({path:`news/${val.id}${type}`})
    }

    
    const { locale } = useI18n()
    const handleChangeLanguage = (e:any) => {
      locale.value = e.target.value
    }

    onMounted(()=>{
      onTab(1)
    })
    const onPage = (val:any) =>{
      // console.log(val)
    }

    
    return {
      ExpandFlag,
      onExpand,
      tab,
      onTab,
      newsData,
      knowleggeData,
      page,
      onNews,
      page_count,
      onPage,
      knowleggeDataT,
      newsDataT,
      locale,
      handleChangeLanguage,
      knowleggeDataENG,
      newsDataENG,
      knowleggeDataENGT,
      newsDataENGT,
      bottomAnchor
    }
  }
};
</script>
<style lang="scss">
.three-line-text {
    -webkit-line-clamp: 5;
}
.one-line-text, .two-line-text, .three-line-text, .four-line-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}
#title{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url('../assets/image/bg02.png'); /* 替換成實際的圖片路徑 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; /* 可以選擇使用fixed固定背景，或者scroll讓背景隨滾動 */  
}

/* 使用媒體查詢調整樣式 */
@media only screen and (max-width: 600px) {
    /* 在螢幕寬度小於或等於 600px 時應用以下樣式 */
    #title {
        height: 100vh; /* 調整高度，視情況而定 */
        background-position: center right;
    }
}

</style>